export const defualtDownSkeletalMeasurementLibrary = [
    {
        id: "Down-300",
        order: 300,
        creator: "Admin",
        name: "POr-NPog",
        type: "Angle AB_CD Inner",
        pointA: "P",
        pointB: "Or",
        pointC: "N",
        pointD: "Pog",
        meaningNormal: "meso-facial subject",
        meaningSmall: "dolico-facial subject",
        meaningLarge: "brachi-facial subject",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 87.8, sd: 3.6,},
        ]
    },
    {
        id: "Down-301",
        order: 301,
        creator: "Admin",
        name: "NAPog",
        type: "Angle A_B_C",
        pointA: "N",
        pointB: "A",
        pointC: "Pog",
        pointD: "",
        meaningNormal: "mesognathic subject",
        meaningSmall:  "prognathic subject",
        meaningLarge:  "retrognathic subject",
        textPoistion:  "",
        offsetX: "0",
        offsetY: "0",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "All", mean: 0, sd: 5.1,},
        ]
    },
    {
        id: "Down-302",
        order: 302,
        creator: "Admin",
        name: "AB-NPog",
        type: "Angle AB_CD Inner",
        pointA: "A",
        pointB: "B",
        pointC: "N",
        pointD: "Pog",
        meaningNormal: "normal divergance ",
        meaningSmall:  "under divergance",
        meaningLarge:  "over divergance",
        textPoistion:  "",
        offsetX: "0",
        offsetY: "0",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "All", mean: -4.6, sd: 3.7,},
        ]
    },
    {
        id: "Down-303",
        order: 303,
        creator: "Admin",
        name: "POr-MeGo",
        type: "Angle AB_CD Inner",
        pointA: "P",
        pointB: "Or",
        pointC: "Me",
        pointD: "Go",
        meaningNormal: "normal divergance ",
        meaningSmall:  "under divergance",
        meaningLarge:  "over divergance",
        textPoistion:  "",
        offsetX: "0",
        offsetY: "0",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "All", mean: 21.9, sd: 3.2,},
        ]
    },
    {
        id: "Down-304",
        order: 304,
        creator: "Admin",
        name: "POr-GnS",
        type: "Angle AB_CD Inner",
        pointA: "P",
        pointB: "Or",
        pointC: "Gn",
        pointD: "S",
        meaningNormal: "normal divergance ",
        meaningSmall:  "horizontal growth",
        meaningLarge:  "vertical growth",
        textPoistion:  "",
        offsetX: "0",
        offsetY: "0",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "All", mean: 59.4, sd: 3.8,},
        ]
    },
]


export const defualtDownDentalMeasurementLibrary = [
    {
        id: "Down-350",
        order: 350,
        creator: "Admin",
        name: "Cant of occlusal plane(POr-Occlussal Plane)",
        type: "Angle AB_CD Inner",
        pointA: "P",
        pointB: "Or",
        pointC: "ppOcP",
        pointD: "Is1l",
        meaningNormal: "normal inclination",
        meaningSmall: "esoinclination",
        meaningLarge: "endoinclination ",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 9.3, sd: 3.8,},
        ]
    },
    {
        id: "Down-351",
        order: 351,
        creator: "Admin",
        name: "Interincisal angle",
        type: "Angle AB_CD Outer",
        pointA: "Is1l",
        pointB: "Ap1l",
        pointC: "Is1u",
        pointD: "Ap1u",
        meaningNormal: "normal inclination",
        meaningSmall:  "esoinclination incisors ",
        meaningLarge:  "esoinclination incisors ",
        textPoistion:  "",
        offsetX: "0",
        offsetY: "0",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "All", mean: 135.4, sd: 5.8,},
        ]
    },
    {
        id: "Down-352",
        order: 352,
        creator: "Admin",
        name: "lower incisor to occlusal plane angle (1l-Occlussal Plan)",
        type: "Angle AB_CD Inner",
        pointA: "Is1l",
        pointB: "Ap1l",
        pointC: "ppOcP",
        pointD: "Is1l",
        meaningNormal: "normal inclination",
        meaningSmall:  "esoinclination incisors",
        meaningLarge:  "esoinclination incisors",
        textPoistion:  "",
        offsetX: "0",
        offsetY: "0",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "All", mean: 14.5, sd: 3.5,},
        ]
    },
    {
        id: "Down-353",
        order: 353,
        creator: "Admin",
        name: "Lower incisor to mandibular plane angle(1l-MeGo)",
        type: "Angle AB_CD Inner",
        pointA: "Is1l",
        pointB: "Ap1l",
        pointC: "Me",
        pointD: "Go",
        meaningNormal: "Normal inclined lower incisors",
        meaningSmall:  "Retroclined lower incisors",
        meaningLarge:  "Proclined lower incisors",
        textPoistion:  "",
        offsetX: "0",
        offsetY: "0",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "All", mean: 92, sd: 5,},
        ]
    },
    {
        id: "Down-354",
        order: 354,
        creator: "Admin",
        name: "1U-APog",
        type: "Distance A_BC",
        pointA: "Is1u",
        pointB: "A",
        pointC: "Pog",
        pointD: "",
        meaningNormal: "normal position of upper maxillary",
        meaningSmall:  "retrusion of upper maxillary",
        meaningLarge:  "protrusion of upper maxillary",
        textPoistion:  "",
        offsetX: "0",
        offsetY: "0",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "All", mean: 2, sd: 3,},
        ]
    },
]