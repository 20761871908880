import { obtainUserToken } from "src/utils/user"

export const SET_AUTHENTICATION = "SET_AUTHENTICATION"

export const setAuthenticationInfo = (idToken) => async dispatch => {
    try {
        //let response = await obtainUserToken(email)
        dispatch({
            type: SET_AUTHENTICATION,
            //payload: { idToken: idToken, email_address: email, token: idToken},
            payload: { token: idToken},
        })
    }
    catch (error) {
        console.log(error)
    }
}
