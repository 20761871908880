export const defualtAboMeasurementLibrary = [
    {
        id: "Abo-500",
        order: 500,
        creator: "Admin",
        name: "U1- NA",
        type: "Distance A_BC",
        pointA: "Is1u",
        pointB: "N",
        pointC: "A",
        pointD: "",
        meaningNormal: "Normal Upper Incisor",
        meaningSmall: "Retroclined Upper Incisor",
        meaningLarge: "Proclined Upper Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 4.3, sd: 1.8,},
        ]
    },
    {
        id: "Abo-501",
        order: 501,
        creator: "Admin",
        name: "U1-SN",
        type: "Angle AB_CD Outer",
        pointA: "Is1u",
        pointB: "Ap1u",
        pointC: "S",
        pointD: "N",
        meaningNormal: "Normal upper Incisor",
        meaningSmall: "Retroclined upper Incisor",
        meaningLarge: "Proclined upper Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 102.8, sd: 5.5,},
        ]
    },
    {
        id: "Abo-502",
        order: 502,
        creator: "Admin",
        name: "L1-NB",
        type: "Distance A_BC",
        pointA: "Is1l",
        pointB: "N",
        pointC: "B",
        pointD: "",
        meaningNormal: "Normal lower Incisor",
        meaningSmall: "Retroclined lower Incisor",
        meaningLarge: "Proclined lower Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 4, sd: 1.8,},
        ]
    },
    {
        id: "Abo-503",
        order: 503,
        creator: "Admin",
        name: "L1-GOGN",
        type: "Angle AB_CD Outer",
        pointA: "Is1l",
        pointB: "Ap1l",
        pointC: "Go",
        pointD: "Gn",
        meaningNormal: "normal inclination",
        meaningSmall: "esoinclination incisors",
        meaningLarge: "endoinclination incisors ",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 93, sd: 6,},
        ]
    },
    {
        id: "Abo-504",
        order: 504,
        creator: "Admin",
        name: "Lower Lip to E-Plane(NS -Pog')",
        type: "Distance A_BC",
        pointA: "Li",
        pointB: "Ns",
        pointC: "Pog’",
        pointD: "",
        meaningNormal: "nornal position of lower lip to E-line",
        meaningSmall: "Retruded Lower lip",
        meaningLarge: "protruded Lower lip",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: -2, sd: 2,},
        ]
    },
    {
        id: "Abo-505",
        order: 505,
        creator: "Admin",
        name: "Upper Lip ro E-Plane(NS -Pog')",
        type: "Distance A_BC",
        pointA: "Ls",
        pointB: "Ns",
        pointC: "Pog’",
        pointD: "",
        meaningNormal: "nornal position of upper lip to E-line",
        meaningSmall: "Retruded upper lip",
        meaningLarge: "protruded upper lip",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: -6, sd: 2,},
        ]
    },
    {
        id: "Abo-506",
        order: 506,
        creator: "Admin",
        name: "Sn-GOGN",
        type: "Angle AB_CD Inner",
        pointA: "S",
        pointB: "N",
        pointC: "Go",
        pointD: "Gn",
        meaningNormal: "Normodivergent Facial Pattern",
        meaningSmall: "Hypodivergent Facial Pattern",
        meaningLarge: "Hyperdivergent Facial Pattern",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 32.9, sd: 5.2,},
        ]
    },
    {
        id: "Abo-507",
        order: 507,
        creator: "Admin",
        name: "FMA ( MP- FH)",
        type: "Angle AB_CD Inner",
        pointA: "Me",
        pointB: "Go",
        pointC: "Or",
        pointD: "P",
        meaningNormal: "normal divergance ",
        meaningSmall: "under divergance ",
        meaningLarge: "over divergance",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 23.9, sd: 4.5,},
        ]
    },
]