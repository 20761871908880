export const defualtStienerMeasurementLibrary = [
    {
        id: "Stiener-100",
        order: 100,
        creator: "Admin",
        name: "SNA",
        type: "Angle A_B_C",
        pointA: "S",
        pointB: "N",
        pointC: "A",
        pointD: "",
        meaningNormal: "Normal Position of A Point",
        meaningSmall: "Back Position of Point A | Retroded Maxilla ",
        meaningLarge: "Forward Position of Point A | Protruded Maxilla",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 84.5, sd: 4.5,},
        ]
    },
    {
        id: "Stiener-101",
        order: 101,
        creator: "Admin",
        name: "SNB",
        type: "Angle A_B_C",
        pointA: "S",
        pointB: "N",
        pointC: "B",
        pointD: "",
        meaningNormal: "Normal Position of B Point",
        meaningSmall: "Back Position of B Point",
        meaningLarge: "Forward Position of B Point, Prognathic Mandible",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 78.5, sd: 3.5,},
        ]
    },
    {
        id: "Stiener-102",
        order: 102,
        creator: "Admin",
        name: "ANB",
        type: "Angle A_B_C",
        pointA: "A",
        pointB: "N",
        pointC: "B",
        pointD: "",
        meaningNormal: "Skeletal Class I",
        meaningSmall: "Skeletal Class III",
        meaningLarge: "Skeletal Class II",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 3, sd: 1,},
        ]
    },
    {
        id: "Stiener-103",
        order: 103,
        creator: "Admin",
        name: "SND",
        type: "Angle A_B_C",
        pointA: "S",
        pointB: "N",
        pointC: "D",
        pointD: "",
        meaningNormal: "Normal Position of Mandible",
        meaningSmall: "Back Position of Mandible",
        meaningLarge: "Forward Position of Mandible",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 76.5, sd: 0.5,},
        ]
    },
    {
        id: "Stiener-104",
        order: 104,
        creator: "Admin",
        name: "II",
        type: "Angle AB_CD Outer",
        pointA: "Is1u",
        pointB: "Ap1u",
        pointC: "Is1l",
        pointD: "Ap1l",
        meaningNormal: "normal inclination",
        meaningSmall: "esoinclination incisors",
        meaningLarge: "endoinclination incisors",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 140, sd: 0,},
        ]
    },
    {
        id: "Stiener-105",
        order: 105,
        creator: "Admin",
        name: "SN-OcP",
        type: "Angle AB_CD Inner",
        pointA: "S",
        pointB: "N",
        pointC: "ppOcP",
        pointD: "Is1l",
        meaningNormal: "Normal Interincisal Angle",
        meaningSmall: "Proclined Interincisal Angle",
        meaningLarge: "Retroclined Interincisal Angle",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 14, sd: 0,},
        ]
    },
    {
        id: "Stiener-106",
        order: 106,
        creator: "Admin",
        name: "SN-GoGn",
        type: "Angle AB_CD Inner",
        pointA: "S",
        pointB: "N",
        pointC: "Go",
        pointD: "Gn",
        meaningNormal: "Normodivergent Facial Pattern",
        meaningSmall: "Hypodivergent Facial Pattern",
        meaningLarge: "Hyperdivergent Facial Pattern",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 30, sd: 0,},
        ]
    },
    {
        id: "Stiener-107",
        order: 107,
        creator: "Admin",
        name: "Max1-NA",
        type: "Angle AB_CD Inner",
        pointA: "Is1u",
        pointB: "Ap1u",
        pointC: "N",
        pointD: "A",
        meaningNormal: "Normal upper Incisor",
        meaningSmall: "Retroclined upper Incisor",
        meaningLarge: "Proclined upper Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 22, sd: 0,},
        ]
    },
    {
        id: "Stiener-108",
        order: 108,
        creator: "Admin",
        name: "Max1-SN",
        type: "Angle AB_CD Outer",
        pointA: "Is1u",
        pointB: "Ap1u",
        pointC: "S",
        pointD: "N",
        meaningNormal: "Normal upper Incisor",
        meaningSmall: "Retroclined upper Incisor",
        meaningLarge: "Proclined upper Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 108, sd: 0,},
        ]
    },
    {
        id: "Stiener-109",
        order: 109,
        creator: "Admin",
        name: "Mand1-NB",
        type: "Angle AB_CD Inner",
        pointA: "Is1l",
        pointB: "Ap1l",
        pointC: "N",
        pointD: "B",
        meaningNormal: "Normal Lower Incisal inclination ",
        meaningSmall: "Retroclined Lower Incisor",
        meaningLarge: "Proclined Lower Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 25, sd: 0,},
        ]
    },
    {
        id: "Stiener-110",
        order: 110,
        creator: "Admin",
        name: "1u-NA",
        type: "Distance A_BC",
        pointA: "Ls1u",
        pointB: "N",
        pointC: "A",
        pointD: "",
        meaningNormal: "Normal Upper Incisor",
        meaningSmall: "Retroclined Upper Incisor",
        meaningLarge: "Proclined Upper Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 4, sd: 0,},
        ]
    },
    {
        id: "Stiener-111",
        order: 111,
        creator: "Admin",
        name: "1l-NB",
        type: "Distance A_BC",
        pointA: "Li1l",
        pointB: "N",
        pointC: "B",
        pointD: "",
        meaningNormal: "Normal lower Incisor",
        meaningSmall: "Retroclined lower Incisor",
        meaningLarge: "Proclined lower Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 4, sd: 0,},
        ]
    },
    {
        id: "Stiener-112",
        order: 112,
        creator: "Admin",
        name: "Pog-NB",
        type: "Distance A_BC",
        pointA: "Pog",
        pointB: "N",
        pointC: "B",
        pointD: "",
        meaningNormal: "Normal Prominence of The Chin",
        meaningSmall: "Retruded Chin / Anterior Region",
        meaningLarge: "Prominent Chin",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 0, sd: 0,},
        ]
    },
    {
        id: "Stiener-113",
        order: 113,
        creator: "Admin",
        name: "Holdaway ratio",
        type: "Devision % Distance ( A_BC ) / Distance (D_EF)",
        pointA: "Li1l",
        pointB: "N",
        pointC: "B",
        pointD: "Pog",
        pointE: "N",
        pointF: "B",
        meaningNormal: "",
        meaningSmall: "",
        meaningLarge: "",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 1, sd: 1,},
        ]
    },
    // {
    //     id: "Stiener-114",
    //     order: 114,
    //     creator: "Admin",
    //     name: "Wits Appraisal",
    //     type: "Distance A_B perp CD",
    //     pointA: "A",
    //     pointB: "B",
    //     pointC: "ppOcP",
    //     pointD: "Is1l",
    //     meaningNormal: "Skeletal Class I",
    //     meaningSmall: "Skeletal Class III",
    //     meaningLarge: "Skeletal Class II ",
    //     textPoistion: "",
    //     offsetX: "",
    //     offsetY: "",
    //     normalValues: [
    //         {from: 18, to: 60, sex: "Male", race: "", mean: -2.24, sd: 0.3,},
    //     ]
    // },
]