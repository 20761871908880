import React, { Suspense, lazy } from "react"
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom"

import LoadingScreen from "src/screens/LoadingScreen"

export const renderRoutes = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingScreen />}>
                <Switch>
                    {routes.map((route, key) => {
                        const Component = route.component
                        return (
                            <Route
                                key={key}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => (
                                    <Component {...props} />
                                )}
                            />
                        )
                    })}
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

const routes = [
    {
        exact: true,
        path: "/",
        component: lazy(() => import("src/views/NotFound")),
    },
    {
        exact: false,
        path: "/service_id=:id",
        component: lazy(() => import("src/views/Ceph")),
    },
    {
        exact: false,
        path: "/services/:id/",
        component: lazy(() => import("src/views/Ceph")),
    },
    {
        exact: false,
        path: "/report_viewer",
        component: lazy(() => import("src/views/ReportViewer")),
    },
    {
        exact: false,
        path: "/library",
        component: lazy(() => import("src/views/Library")),
    },
    {
        component: () => <Redirect to="/" />
    }
]

export default routes
