export const defualtEastmanMeasurementLibrary = [
    {
        id: "Eastman-600",
        order: 600,
        creator: "Admin",
        name: "SNA",
        type: "Angle A_B_C",
        pointA: "S",
        pointB: "N",
        pointC: "A",
        pointD: "",
        meaningNormal: "Normal Position of A Point",
        meaningSmall: "Back Position of A Point",
        meaningLarge: "Forward Position of A Point, Protruded Maxilla",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 82, sd: 3,},
        ]
    },
    {
        id: "Eastman-601",
        order: 601,
        creator: "Admin",
        name: "SNB",
        type: "Angle A_B_C",
        pointA: "S",
        pointB: "N",
        pointC: "B",
        pointD: "",
        meaningNormal: "Normal Position of B Point",
        meaningSmall: "Back Position of B Point",
        meaningLarge: "Forward Position of B Point, Prognathic Mandible",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 79, sd: 3,},
        ]
    },
    {
        id: "Eastman-602",
        order: 602,
        creator: "Admin",
        name: "ANB",
        type: "Angle A_B_C",
        pointA: "A",
        pointB: "N",
        pointC: "B",
        pointD: "",
        meaningNormal: "Skeletal Class I",
        meaningSmall: "Skeletal Class III",
        meaningLarge: "Skeletal Class II",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 3, sd: 1,},
        ]
    },
    {
        id: "Eastman-603",
        order: 603,
        creator: "Admin",
        name: "SN to maxillary plane",
        type: "Angle AB_CD Inner",
        pointA: "S",
        pointB: "N",
        pointC: "ANS",
        pointD: "PNS",
        meaningNormal: "Normal SN-MxPl Angle",
        meaningSmall: "Small SN-MxPl Angle",
        meaningLarge: "Large SN-MxPl Angle",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 8, sd: 3,},
        ]
    },
    // {
    //     id: "Eastman-604",
    //     order: 604,
    //     creator: "Admin",
    //     name: "Wits appraisal",
    //     type: "Distance A_B perp CD",
    //     pointA: "A",
    //     pointB: "B",
    //     pointC: "ppOcP",
    //     pointD: "Is1l",
    //     meaningNormal: "Skeletal Class I",
    //     meaningSmall: "Skeletal Class III",
    //     meaningLarge: "Skeletal Class II",
    //     textPoistion: "",
    //     offsetX: "",
    //     offsetY: "",
    //     normalValues: [
    //         {from: 18, to: 60, sex: "Male", race: "", mean: -2.24, sd: 0.3,},
    //     ]
    // },
    {
        id: "Eastman-612",
        order: 612,
        creator: "Admin",
        name: "L1 to A-Pog(Eastman)",
        type: "Distance A_BC",
        pointA: "Is1l",
        pointB: "A",
        pointC: "Pog",
        pointD: "",
        meaningNormal: "Normal Lower Incisor",
        meaningSmall: "Retruded Lower Incisor",
        meaningLarge: "protruded lower incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 1, sd: 1,},
        ]
    },
    {
        id: "Eastman-609",
        order: 609,
        creator: "Admin",
        name: "Upper anterior face height",
        type: "Distance A_B",
        pointA: "N",
        pointB: "ANS",
        pointC: "",
        pointD: "",
        meaningNormal: "normal upper AFH",
        meaningSmall: "small upper AFH",
        meaningLarge: "large upper AFH",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 54, sd: 5,},
        ]
    },

    {
        id: "Eastman-610",
        order: 610,
        creator: "Admin",
        name: "Lower anterior face height",
        type: "Distance A_B",
        pointA: "ANS",
        pointB: "Me",
        pointC: "",
        pointD: "",
        meaningNormal: "normal lower AFH",
        meaningSmall: "small lower AFH",
        meaningLarge: "large lower AFH",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 65, sd: 5,},
        ]
    },
    {
        id: "Eastman-611",
        order: 611,
        creator: "Admin",
        name: "Lower AFH ratio",
        type: "Devision % Distance ( A_B ) / Distance (C_D)",
        pointA: "N",
        pointB: "ANS",
        pointC: "ANS",
        pointD: "Me",
        meaningNormal: "normal lower AFH",
        meaningSmall: "small lower AFH",
        meaningLarge: "large lower AFH",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 55, sd: 2,},
        ]
    },
    {
        id: "Eastman-605",
        order: 605,
        creator: "Admin",
        name: "U1 to maxillary plane angle",
        type: "Angle AB_CD Outer",
        pointA: "Is1u",
        pointB: "Ap1u",
        pointC: "ANS",
        pointD: "PNS",
        meaningNormal: "Normal Upper Incisor Relative to Maxillary Plane",
        meaningSmall: "Retroclined Upper Incisor",
        meaningLarge: "Proclined Upper Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 108, sd: 5,},
        ]
    },
    {
        id: "Eastman-606",
        order: 606,
        creator: "Admin",
        name: "L1 to mandibular plane angle",
        type: "Angle AB_CD Outer",
        pointA: "Is1l",
        pointB: "Ap1l",
        pointC: "Me",
        pointD: "Go",
        meaningNormal: "Normal Lower Incisor Relative to Mandibular Plane",
        meaningSmall: "Retroclined Lower Incisor",
        meaningLarge: "Proclined Lower Incisor",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 92, sd: 5,},
        ]
    },
    {
        id: "Eastman-607",
        order: 607,
        creator: "Admin",
        name: "Interincisal angle(Eastman)",
        type: "Angle AB_CD Outer",
        pointA: "Is1u",
        pointB: "Ap1u",
        pointC: "Is1l",
        pointD: "Ap1l",
        meaningNormal: "Normal Interincisal Angle",
        meaningSmall: "Proclined Interincisal Anlge",
        meaningLarge: "Retroclined Interincisal Angle",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 133, sd: 10,},
        ]
    },
    {
        id: "Eastman-608",
        order: 608,
        creator: "Admin",
        name: "Maxillary mandibular planes angle",
        type: "Angle AB_CD Inner",
        pointA: "ANS",
        pointB: "PNS",
        pointC: "Me",
        pointD: "Go",
        meaningNormal: "normodivergent pattern",
        meaningSmall: "hypodivergent pattern ",
        meaningLarge: "hyperdivergent pattern",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 27, sd: 5,},
        ]
    },
    {
        id: "Eastman-614",
        order: 614,
        creator: "Admin",
        name: "Nasolabial angle",
        type: "Angle A_B_C",
        pointA: "CoTg",
        pointB: "Sn",
        pointC: "Ls",
        pointD: "",
        meaningNormal: "normal position of lip",
        meaningSmall: "protruded lip",
        meaningLarge: "retruded lip",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: 95, sd: 5,},
        ]
    },
    {
        id: "Eastman-613",
        order: 613,
        creator: "Admin",
        name: "Lower lip to E-plane",
        type: "Distance A_BC",
        pointA: "Li",
        pointB: "Pr",
        pointC: "Pog’",
        pointD: "",
        meaningNormal: "normal position of lowe lip to E-line",
        meaningSmall: "Retruded Lower lip",
        meaningLarge: "protruded lower lip",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            {from: 18, to: 60, sex: "Male", race: "", mean: -2, sd: 1,},
        ]
    },
]