import React from "react"
import { Provider } from "react-redux"
import { SnackbarProvider } from "notistack"

import { createMyTheme } from "./theme"
import rtl from "jss-rtl"
import { create } from "jss"
import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/core"
import useSettings from "./hooks/useSettings"

import routes, { renderRoutes } from "./router"

import store from "./redux/store"
import { SettingsProvider } from "./contexts/SettingsContext"
import { AuthProvider } from "./contexts/KeyClockContext"
import './App.css'
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

const RTL = props => (
  <StylesProvider jss={jss}>
    {props.children}
  </StylesProvider>
)

const App = () => {
  const { settings } = useSettings()

  const theme = createMyTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  })

  return (
    <Provider store={store}>
      <SettingsProvider>
        <RTL>
          <ThemeProvider theme={theme}>
            <StylesProvider jss={jss}>
                <AuthProvider>
                  <SnackbarProvider maxSnack={4}>
                    {renderRoutes(routes)}
                  </SnackbarProvider>
                </AuthProvider>
            </StylesProvider>
          </ThemeProvider>
        </RTL>
      </SettingsProvider>
    </Provider>
  )
}

export default App

