import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import logger from "redux-logger"
import thunk from "redux-thunk"

import cephalometry from "./reducers/cephalometry"
import user from "./reducers/user"
import library from "./reducers/library"

const store = createStore(
    combineReducers({
        user, cephalometry, library
    }),
    compose(
        //applyMiddleware(thunk, logger)
        applyMiddleware(thunk)
    )
)

export default store
