import { createTheme, responsiveFontSizes } from "@material-ui/core"

export const createMyTheme = (config = {}) => {
  let theme = createTheme({
    direction: "ltr",
    typography: {
      fontFamily: "Rubik, sans-serifRoboto, Cairo",
      h1: {
        fontSize: 64,
        fontWeight: 500,
        "@media (max-width:768px)": { // tablet 
          fontSize: 58,
        },
        "@media (max-width:425px)": { // mobile 
          fontSize: 32,
        },
      },
      h2: {
        fontSize: 48,
        fontWeight: 500,
        "@media (max-width:768px)": { // tablet 
          fontSize: 28,
        },
        "@media (max-width:425px)": { // mobile 
          fontSize: 24,
        },
      },
      h3: {
        fontSize: 32,
        fontWeight: 500,
      },
      h4: {
        fontSize: 28,
        fontWeight: 500,
        "@media (max-width:425px)": { // mobile 
          fontSize: 20,
        },
      },
      h5: {
        fontSize: 24,
        fontWeight: 500,
      },
      h6: {
        fontSize: 20,
        fontWeight: 500,
      },
      button: {
        fontSize: 16,
        fontWeight: 500,
      },
      body1: {
        fontSize: 16,
        fontWeight: 300,
        "@media (max-width:425px)": { // mobile 
          fontSize: 14,
        },
      },
      body2: {
        fontSize: 14,
        fontWeight: 300,
        "@media (max-width:425px)": { // mobile 
          fontSize: 12,
        },
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 500,
        color: "#6b6b6b",
        "@media (max-width:425px)": { // mobile 
          fontSize: 14,
        },
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 600,
        color: "#1a90d2"
      },
      caption: {
        fontSize: 12,
        fontWeight: 400,
      }
    },
    overrides: {
      MuiToggleButton: {
        root: {
          color: "#000",
          border: "none",
        }
      },
      MuiDataGrid: {
        root: {
          '& .Mui-selected': {
            backgroundColor: 'red',
          },
          '& .MuiDataGrid-selectedRowCount': {
            backgroundColor: 'red',
          },

        }
      },
      MuiTableCell: {
        root: {
          padding: "8px"
        }
      },
      MuiDialog: {
        paper: {
          borderRadius: "24px",
          padding: "24px 12px",
          maxWidth: "500px"
        }
      },
      MuiSlider: {
        thumb: {
          marginTop: "-5px",
          flexGrow: "0",
          width: "24px",
          height: "16px",
          marginLeft: -12,
          alignSelf: "center",
          borderRadius: "50px",
          margin: "0 110px 8px 98px",
          backgroundColor: "#ffffff",
          border: "solid 2px #ea5a43",
          boxShadow: "0 9px 25px 0 rgba(234, 90, 67, 0.35)",
          '&:focus, &:hover, &:active': {
            boxShadow: '#ccc 0 2px 3px 1px',
          },
          '& .bar': {
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
          },
        },
        track: {
          visibility: "hidden"
        },
        rail: {
          height: "8px",
          color: "#e9e9e9",
          borderRadius: "16px"
        },
        root: {
          height: "8px",
          color: "#e9e9e9",
          borderRadius: "16px"
        },
        markLabel: {
          color: "#8e8e8e"
        },
        markLabelActive: {
          color: "#ea5a43"
        }
      },
      MuiButton: {
        sizeLarge: {
          height: "52px",
          fontSize: "16px"
        },
        text: {
          color: "red",
          textTransform: "none",
          borderRadius: "8px",
        },
        containedPrimary: {
          textTransform: "none",
          borderRadius: "8px",
          boxShadow: "0 9px 25px 0 rgba(234, 90, 67, 0.35)",
        },
        containedSecondary: {
          textTransform: "none",
          borderRadius: "8px",
        },
        outlinedPrimary: {
          textTransform: "none",
          borderRadius: "8px",
          color: "#6b6b6b",
          border: "solid 2px #e9e9e9",
        },
        textSecondary: {
          fontFamily: "Rubik, sans-serifRoboto, Cairo",
          textTransform: "none",
          color: "#8e8e8e",
          fontSize: 14,
        }
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: "#f5f5f5"
        },
        root: {
          boxShadow: "none"
        }
      },
      MuiTextField: {
        root: {
          backgroundColor: "#f5f5f5",
          border: "solid 1px #f5f5f5",
          borderRadius: "12px",
          "& .MuiOutlinedInput-root": {
            "&:hover": {
              backgroundColor: "#fcfcfc",
              borderRadius: "12px",
            },
            "&:focused": {
              backgroundColor: "#fcfcfc",
              borderRadius: "12px",
            },
            "& fieldset": {
              border: "solid 1px #f5f5f5",
              borderRadius: "12px",
            },
            "&:hover fieldset": {
              border: "solid 1px #8e8e8e",
              borderRadius: "12px",
            },
            "&.Mui-focused fieldset": {
              border: "solid 2px #8e8e8e",
              borderRadius: "12px",
            },
          },
          "& .MuiFormLabel-root": {
            color: "#6b6b6b",
          }
        },
      },
      MuiDrawer: {
        paperAnchorDockedLeft: {
          borderRight: "none"
        }
      },
      MuiFormControl: {
        root: {
          width: "100%",
          "& .MuiFilledInput-root": {
            border: "solid 1px #f5f5f5",
            borderRadius: "12px",
            backgroundColor: "#f5f5f5",
          },
          "& .MuiFilledInput-root.Mui-focused .MuiFilledInput-input": {
            border: "solid 1px #f5f5f5",
            borderRadius: "12px",
            backgroundColor: "#f5f5f5",
          },
        }
      },
      MuiSelect: {
        filled: {
          border: "solid 1px #f5f5f5",
          backgroundColor: "#f5f5f5",
          borderRadius: "12px",
        }
      }
    },
    palette: {
      type: "light",
      primary: {
        main: "#ea5a43",
        light: "#F7C0B7",
      },
      secondary: {
        main: "#1A90D2",
        light: "#A8D5EE",
      },
      text: {
        primary: "#171716",
        secondary: "#6b6b6b",
      },
      info: {
        dark: "#22272e",
        main: "#8e8e8e",
      },
      warning: {
        main: "#ECAB08",
      },
      success: {
        main: "#00966D",
        light: "#00BA88",
      },
      error: {
        main: "#C30052",
        light: "#ED2E7E",
      },
      action: {
        active: "#8e8e8e",
      },
    }
  })

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
