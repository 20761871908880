import { defaultMeasurementState } from "src/constant/constants"
import { LOAD_LIBRARIES, ADD_NEW_ANALYSIS, DELETE_ANALYSIS, ADD_MEASUREMNT_ANALYSIS_LIST, REMOVE_MEASURMENT_ANALYSIS_LIST, SELECT_ANALYSIS, SELECT_LIBRARY_MEASUREMENTS, SELECT_MEASUREMENT, DELETE_MEASUREMENT, ADD_NEW_MEASUREMENT, UPDATE_SELECTED_MEASUREMENT, SAVE_SELECTED_MEASUREMENT_UPDATES } from "../actions/library"
import uuid from 'react-uuid'

export const LibraryInitalState = {
    // LibraryId = Id of user study in backend.
    libraryId: "",

    selectedAnalysis: {},

    analysisList: [],
    measuremntLibrary: [],

    selectedMeasurement: {},
    selectedMeasurementChange: false,

    selectdLibraryMeasurements: [],
}

const libraryReducer = (state = LibraryInitalState, action) => {
    switch (action.type) {
        case LOAD_LIBRARIES:
            return { ...state, libraryId: action.payload.libraryId, measuremntLibrary: [...action.payload.measuremntLibrary], analysisList: [...action.payload.analysisList], selectedAnalysis: action.payload.analysisList[0] }
        case SELECT_ANALYSIS:
            return { ...state, selectedAnalysis: state.analysisList.filter((analysis) => analysis.id === action.payload.id)[0] }
        case ADD_NEW_ANALYSIS:
            return { ...state, analysisList: [...state.analysisList, { id: uuid(), name: action.payload.analysisName, creator: "User", measurements: [] }] }
        case DELETE_ANALYSIS: {
            let newAnalysisList = state.analysisList.filter((analysis) => analysis.id != action.payload.id)
            return { ...state, analysisList: newAnalysisList, selectedAnalysis: newAnalysisList[0] }
        }

        case SELECT_MEASUREMENT:
            return { ...state, selectedMeasurement: state.measuremntLibrary.filter((measurement) => measurement.id === action.payload.id)[0] }
        case ADD_NEW_MEASUREMENT: {
            let newMeasurement = { ...defaultMeasurementState, id: uuid(), order: state.measuremntLibrary.length, creator: "User" }
            return { ...state, selectedMeasurement: newMeasurement, selectedMeasurementChange: true }
        }
        case DELETE_MEASUREMENT: {
            let newMeasurementLibrary = state.measuremntLibrary.filter((measurement) => measurement.id != action.payload.id)
            return { ...state, measuremntLibrary: newMeasurementLibrary, selectedMeasurement: newMeasurementLibrary[0] }
        }
        case UPDATE_SELECTED_MEASUREMENT:
            let updatedMeasurement = { ...state.selectedMeasurement, ...action.payload }
            return { ...state, selectedMeasurement: updatedMeasurement, selectedMeasurementChange: true }
        case SAVE_SELECTED_MEASUREMENT_UPDATES: {
            let index = state.measuremntLibrary.findIndex((item) => item.id === state.selectedMeasurement.id)
            let newLibrary = [...state.measuremntLibrary]
            if (index === -1)
                newLibrary.push({ ...state.selectedMeasurement })
            else {
                newLibrary[index] = { ...state.selectedMeasurement }
            }
            let updatedAnalysisList = state.analysisList.map((analysis) => {
                let index = analysis.measurements.findIndex((m) => m.id === state.selectedMeasurement.id)
                if(index != -1) {
                    analysis.measurements[index] = state.selectedMeasurement
                }
                return analysis
            })
            return { ...state, measuremntLibrary: [...newLibrary], selectedMeasurementChange: false, analysisList: [...updatedAnalysisList] }
        }

        case SELECT_LIBRARY_MEASUREMENTS:
            return { ...state, selectdLibraryMeasurements: action.payload.measurementList }
        case ADD_MEASUREMNT_ANALYSIS_LIST: {
            let updatedAnalysis = { ...state.selectedAnalysis, measurements: [...state.selectedAnalysis.measurements, ...state.selectdLibraryMeasurements] }
            let index = state.analysisList.findIndex((analysis) => analysis.id === updatedAnalysis.id)
            let newAnalysisList = [...state.analysisList]
            newAnalysisList[index] = updatedAnalysis
            return { ...state, selectedAnalysis: updatedAnalysis, analysisList: newAnalysisList, selectdLibraryMeasurements: [], appliedAnalysis: updatedAnalysis }
        }
        case REMOVE_MEASURMENT_ANALYSIS_LIST: {
            let newAnalysisMeasurements = state.selectedAnalysis.measurements.filter((iteme) => iteme.id != action.payload.id)
            let updatedAnalysis = { ...state.selectedAnalysis, measurements: newAnalysisMeasurements }
            let index = state.analysisList.findIndex((analysis) => analysis.id === updatedAnalysis.id)
            let newAnalysisList = [...state.analysisList]
            newAnalysisList[index] = updatedAnalysis
            return { ...state, selectedAnalysis: updatedAnalysis, analysisList: newAnalysisList, selectdLibraryMeasurements: [], appliedAnalysis: updatedAnalysis }
        }
        default:
            return state
    }
}

export default libraryReducer