import axios from "../utils/axios";
import uuid from "react-uuid";

const MEASUREMENTS_SHEET_URL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQAAp4O4_WH2vg3HOpo0L-QfGM5b_zLDRKx4d9WCUJeuTHvODGgKtdNEIZ2UwC7qN6xog_1odJ9iNF3/pub?gid=59212826&single=true&output=csv';
const ANALYSIS_SHEET_URL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQAAp4O4_WH2vg3HOpo0L-QfGM5b_zLDRKx4d9WCUJeuTHvODGgKtdNEIZ2UwC7qN6xog_1odJ9iNF3/pub?gid=1292297771&single=true&output=csv';
const CUSTOM_MEASUREMENTS_SHEET_URL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQAAp4O4_WH2vg3HOpo0L-QfGM5b_zLDRKx4d9WCUJeuTHvODGgKtdNEIZ2UwC7qN6xog_1odJ9iNF3/pub?gid=924267952&single=true&output=csv';


const fetchCSVData = async (url) => {
    try {
        let response = await axios.get(url)
        return response.data;
    }
    catch(error) {
        console.error('Error fetching CSV data:', error);
    }
}

/**
 *
 * Measurements Sheet Column Stucture:
 *      [00] Code       | [01] Name     | [2] Type      | [03] Classification | [4, --- 9] Points A -> F
 *      [10] Mean(M)    | [11] SD(M)    | [12] Mean(F)  | [13] SD(F)          | [14, --- 16] Meaning (Normal, Small, Large)
 *
 * @returns List Of Measurements Object
 */
const fetchMeasurementsSheet = async () => {
    try {
        let csvText = await fetchCSVData(MEASUREMENTS_SHEET_URL)
        const rows = csvText.split(/\r?\n/);
        const data = [];
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');
            const rowObject = {
                id: "Mesu-" + uuid(),
                order: i,
                creator: "Admin",
                code: rowData[0] ?? "",
                name: rowData[1] ?? "",
                type: rowData[2] ?? "",
                classification: rowData[3] ?? "",
                pointA: rowData[4] ?? "",
                pointB: rowData[5] ?? "",
                pointC: rowData[6] ?? "",
                pointD: rowData[7] ?? "",
                pointE: rowData[8] ?? "",
                pointF: rowData[9] ?? "",
                meaningNormal: rowData[14] ?? "",
                meaningSmall: rowData[15] ?? "",
                meaningLarge: rowData[16] ?? "",
                textPoistion: "",
                offsetX: "",
                offsetY: "",
                normalValues: [
                    { from: 18, to: 60, sex: "Male",   race: "All", mean: parseFloat(rowData[10] ?? "0.0"), sd: parseFloat(rowData[11] ?? "0.0"), },
                    { from: 18, to: 60, sex: "Female", race: "All", mean: parseFloat(rowData[12] ?? "0.0"), sd: parseFloat(rowData[13] ?? "0.0"), },
                ]
            };
            data.push(rowObject);
        }
        return data;
    }
    catch(error) {
        console.log(error);
    }
}

const fetchAnalysisSheet = async () => {
    try {
        let csvText = await fetchCSVData(ANALYSIS_SHEET_URL)
        const rows = csvText.split(/\r?\n/);
        const headers = rows[0].split(',');
        const data = [];
        for (let i = 0; i < headers.length; i++) {
            const analysisObject = {
                id: "system-" + (i + 1),
                name: headers[i],
                creator: "Admin",
                measurements: [],
            };
            data.push(analysisObject);
        }
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');
            for (let j = 0; j < rowData.length; j++) {
                if(rowData[j]) {
                    data[j].measurements = [...data[j].measurements, rowData[j] ?? ""]
                }
            }
        }
        return data;
    }
    catch(error) {
        console.log(error)
    }
}

const fetchCustomMeasurementsSheet = async () => {
    try {
        let csvText = await fetchCSVData(CUSTOM_MEASUREMENTS_SHEET_URL)
        const rows = csvText.split(/\r?\n/);
        const data = [];
        for (let i = 1; i < rows.length; i++) {
            const rowData = rows[i].split(',');
            const rowObject = {
                code: rowData[0],
                formula: rowData[1],
                variables: rowData[2].split('#'),
                unit: rowData[3]
            };
            data.push(rowObject);
        }
        return data;
    }
    catch(error) {
        console.log(error);
    }
}

export const configCustomMeasurements = async (measurements) => {
    let customsConfig = await fetchCustomMeasurementsSheet()
    let updatedMeasuerments = [...measurements]
    for(let config of customsConfig) {
        let index = measurements.findIndex((m) => m.code == config.code)
        if(index == -1) break;
        let variablesList= [];
        config.variables.forEach((variable) => {
            let measurement = measurements.find((m) => m.code == variable)
            if(measurement != null || measurement != undefined) variablesList.push(measurement);
        })
        updatedMeasuerments[index] = {
            ...updatedMeasuerments[index],
            formula: config.formula,
            unit: config.unit,
            variables: config.variables,
            variables_objects: variablesList
        }
    }
    return updatedMeasuerments;
}

const combineMeasuremnetsToAnalysis = (analysises, measurements) => {
    let updatedAnalysisList = [];
    for(let i = 0; i < analysises.length; i++) {
        let requiredMeasurements = [...analysises[i].measurements]
        let targetMeasurements = []
        for(let j = 0; j < requiredMeasurements.length; j++) {
            let measurement = measurements.find((item) => item.name == requiredMeasurements[j])
            targetMeasurements.push({...measurement, order: j + 1})
        }
        updatedAnalysisList.push({
            ...analysises[i],
            measurements: targetMeasurements,
        })
    }
    return updatedAnalysisList;
}

export const getCephaloLibrary = async () => {
    let analysises = await fetchAnalysisSheet()
    let measurements = await fetchMeasurementsSheet()

    let updatedAnalysises = combineMeasuremnetsToAnalysis(analysises, measurements);
    return {
        analysises: updatedAnalysises,
        measurements: measurements,
    }
}

export const getAllUserStudy =  async (token)  =>  {
    try {
        var config = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        let response = await axios.get(`https://voxel3di.com/en/api/v1/customer/my-study/`, config)
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const createUserStudy = async (token, userData) => {
    var config = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
    };
    var data = JSON.stringify({
        "study_data": userData
    });
    try {
        let response = await axios.post(`https://voxel3di.com/en/api/v1/customer/my-study/`, data, config)
        return response.data
    }
    catch(error) {
        console.log(error)
    }
}

export const updateUserStudy = async (token, userData, userStudyId) => {
    var config = {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
    };
    var data = JSON.stringify({
        "study_data": userData
    });
    try {
        let response = await axios.put(`https://voxel3di.com/en/api/v1/customer/my-study/${userStudyId}/`, data, config)
        return response.data
    }
    catch(error) {
        console.log(error)
    }
}