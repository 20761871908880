import { createContext, useEffect, useState } from "react"
import {useDispatch} from "react-redux"

import {setAuthenticationInfo} from "../redux/actions/user"

import Keycloak from "keycloak-js"

export const AuthContext = createContext()

const config = {
    pkceMethod: 'S256',
    url: 'https://sso.voxel3di.com:8443/auth',
    realm: 'voxel_portal',
    clientId: 'web',
    onLoad: 'check-sso',
    //silentCheckSsoRedirectUri: 'https://dashboard.voxel3di.com/silent-check-sso.html',
}

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setAuth] = useState('TryToLogin')
    const [_keyClock, ] = useState(new Keycloak(config))

    const dispatch = useDispatch()

    const initKeyClock = async () => {
        try {
            if(getQueryVariable('token') != false) {
                let token = getQueryVariable('token')
                setAuth('IsAuth')
                dispatch(setAuthenticationInfo(token))
                return;
            }
            else {
                let authenticated = await _keyClock.init(config)
                authenticated ? setAuth('IsAuth') : _keyClock.login()
                //console.log(_keyClock.idToken)
                dispatch(setAuthenticationInfo(getIdToken()))
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const getQueryVariable = (variable) => {
        var queryAsString = window.location.search.substring(1);
        var queryItems = queryAsString.split("&");
        for(let item of queryItems ) {
            var pair = item.split("=");
            if( pair[0] == variable ) return pair[1]
        }
        return false;
    }

    const refreshToken = () => {
        setInterval(() => {
            _keyClock.updateToken(70).then((refreshed) => {
              if (refreshed) {
                //console.log('Token refreshed' + refreshed);
              } else {
                //console.log('Token not refreshed, valid for ' + Math.round(_keyClock.tokenParsed.exp + _keyClock.timeSkew - new Date().getTime() / 1000) + ' seconds');
              }
            }).catch(function(){
              //console.log('Failed to refresh token')
            });
        }, 6000);
    }

    const getIdToken = () => _keyClock.idToken;

    //const getEmail = () => _keyClock.loadUserProfile().email;

    useEffect(async () => {
        initKeyClock()
    }, [])

    const value = {
        isAuthenticated,
    }

    return (
        <AuthContext.Provider value={value}>
           {children}
        </AuthContext.Provider>
    )
}