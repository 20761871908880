export const defualtTweedMeasurementLibrary = [
    {
        id: "Tweed-001",
        order: 1,
        creator: "Admin",
        name: "FMIA (L1 -FH)",
        type: "Angle AB_CD Inner",
        pointA: "Is1l",
        pointB: "Ap1l",
        pointC: "Or",
        pointD: "P",
        meaningNormal: "normal inclination inferior incisors",
        meaningSmall: "esoinclination inferior incisors",
        meaningLarge: "endoinclination inferior incisors",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 68, sd: 3,},
        ]
    },
    {
        id: "Tweed-002",
        order: 2,
        creator: "Admin",
        name: "FMA (MP- FH)",
        type: "Angle AB_CD Inner",
        pointA: "Me",
        pointB: "Go",
        pointC: "Or",
        pointD: "P",
        meaningNormal: "normal divergance",
        meaningSmall: "under divergance",
        meaningLarge: "over divergance",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 25.5, sd: 9.5,},
        ]
    },
    {
        id: "Tweed-003",
        order: 3,
        creator: "Admin",
        name: "IMPA (L1 - MP)",
        type: "Angle AB_CD Outer",
        pointA: "Is1l",
        pointB: "Ap1l",
        pointC: "Me",
        pointD: "Go",
        meaningNormal: "normal inclination inferior incisors",
        meaningSmall: "endoinclination inferior incisors",
        meaningLarge: "esoinclination inferior incisors",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 88, sd: 4,},
        ]
    },
    {
        id: "Tweed-004",
        order: 4,
        creator: "Admin",
        name: "Wits",
        type: "Distance A prep CD_B perp CD",
        pointA: "A",
        pointB: "B",
        pointC: "ppOcP",
        pointD: "Is1l",
        meaningNormal: "Skeletal Class I",
        meaningSmall: "Skeletal Class III",
        meaningLarge: "Skeletal Class II",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 2, sd: 2,},
        ]
    },
    {
        id: "Tweed-005",
        order: 5,
        creator: "Admin",
        name: "Frankfurt Horizontal / Occlussal plan",
        type: "Angle AB_CD Inner",
        pointA: "Or",
        pointB: "P",
        pointC: "ppOcP",
        pointD: "Is1l",
        meaningNormal: "normal angulation",
        meaningSmall: "hypodivergent angle ",
        meaningLarge: "hyperdivergent angle",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 10, sd: 2,},
        ]
    },
    {
        id: "Tweed-006",
        order: 6,
        creator: "Admin",
        name: "Z",
        type: "Angle AB_CD Outer",
        pointA: "Or",
        pointB: "P",
        pointC: "Ls",
        pointD: "Pog’",
        meaningNormal: "normal profile ",
        meaningSmall: "concave profile",
        meaningLarge: "convex profile",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 76.5, sd: 1.5,},
        ]
    },
    {
        id: "Tweed-007",
        order: 7,
        creator: "Admin",
        name: "PFH ( S - Go)",
        type: "Distance A_B",
        pointA: "S",
        pointB: "Go",
        pointC: "",
        pointD: "",
        meaningNormal: "",
        meaningSmall: "",
        meaningLarge: "",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 45, sd: 2,},
        ]
    },
    {
        id: "Tweed-008",
        order: 8,
        creator: "Admin",
        name: "AFH ( N- Me)",
        type: "Distance A_B",
        pointA: "N",
        pointB: "Me",
        pointC: "",
        pointD: "",
        meaningNormal: "",
        meaningSmall: "",
        meaningLarge: "",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 65, sd: 3,},
        ]
    },
    {
        id: "Tweed-009",
        order: 9,
        creator: "Admin",
        name: "PFH/AFH",
        type: "Devision % Distance ( A_B ) / Distance (C_D)",
        pointA: "S",
        pointB: "Go",
        pointC: "N",
        pointD: "Me",
        meaningNormal: "skeletal normal bite",
        meaningSmall: "skeletal open bite",
        meaningLarge: "skeletal deep bite",
        textPoistion: "",
        offsetX: "",
        offsetY: "",
        normalValues: [
            { from: 18, to: 60, sex: "Male", race: "All", mean: 65, sd: 4,},
        ]
    },
]


