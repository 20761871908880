import { defaultAnalysisList, defualtMeasurementLibrary } from "src/constant/constants"

import { createUserStudy, getAllUserStudy, updateUserStudy, getCephaloLibrary, configCustomMeasurements } from "../../utils/library"
import store from "../store"

export const LOAD_LIBRARIES = "LOAD_LIBRARIES"

export const SELECT_ANALYSIS = "SELECT_ANALYSIS"
export const ADD_NEW_ANALYSIS = "ADD_NEW_ANALYSIS"
export const DELETE_ANALYSIS = "DELETE_ANALYSIS"

export const SELECT_MEASUREMENT = "SELECT_MEASUREMENT"
export const ADD_NEW_MEASUREMENT = "ADD_NEW_MEASUREMENT"
export const DELETE_MEASUREMENT = "DELETE_MEASUREMENT"
export const UPDATE_SELECTED_MEASUREMENT = "UPDATE_SELECTED_MEASUREMENT"
export const SAVE_SELECTED_MEASUREMENT_UPDATES = "SAVE_SELECTED_MEASUREMENT_UPDATES"

export const SELECT_LIBRARY_MEASUREMENTS = "SELECT_LIBRARY_MEASUREMENTS"
export const ADD_MEASUREMNT_ANALYSIS_LIST = "ADD_MEASUREMNT_ANALYSIS_LIST"
export const REMOVE_MEASURMENT_ANALYSIS_LIST = "REMOVE_MEASURMENT_ANALYSIS_LIST"

const combineMeasuremnetsToAnalysis = (analysises, measurements) => {
    let updatedAnalysisList = [];
    for(let i = 0; i < analysises.length; i++) {
        let requiredMeasurements = [...analysises[i].measurements]
        let targetMeasurements = []
        for(let j = 0; j < requiredMeasurements.length; j++) {
            let measurement = measurements.find((item) => item.name == requiredMeasurements[j].name)
            targetMeasurements.push({...measurement, order: j + 1})
        }
        updatedAnalysisList.push({
            ...analysises[i],
            measurements: targetMeasurements,
        })
    }
    return updatedAnalysisList;
}

export const loadLibraries = (token) => async dispatch => {
    let response = await getAllUserStudy(token)
    if (response) {
        let libraryId = null;
        let library = {};

        if (response.results.length == 0) {
            let newUserStudy = await createUserStudy(
                token,
                { analysis_list: [], measurement_library: [] },
            )
            if(newUserStudy) {
                libraryId = response['id']
                library = response['study_data']
            }
        }
        else {
            libraryId = response.results[0]['id']
            library = response.results[0]['study_data']
        }

        let cephaloLibrary = await getCephaloLibrary();
        let allMeasurements = [...library['measurement_library'], ...cephaloLibrary.measurements]
        let allAnalysis = [...library['analysis_list'], ...cephaloLibrary.analysises]

        let updatedMeasuerments = await configCustomMeasurements(allMeasurements)
        let updatedAnalysises = combineMeasuremnetsToAnalysis(allAnalysis, updatedMeasuerments)
        dispatch({
            type: LOAD_LIBRARIES,
            payload: {
                libraryId: libraryId,
                analysisList: updatedAnalysises,
                measuremntLibrary: updatedMeasuerments,
            }
        })
    }
}

const updateLibraryState = async () => {
    // [1] Get Analysis List and Measuerment Library from Store
    let analysisList = store.getState().library.analysisList
    let measuremntLibrary = store.getState().library.measuremntLibrary

    // [2] Remove System Analysis and Measuerment
    let userAnalysisList = []
    analysisList.forEach((analysis) => {
        if(analysis.creator != "Admin")
            userAnalysisList.push(analysis)
    })

    let userMeasuementLibrary = []
    measuremntLibrary.forEach((measurement) => {
        if(measurement.creator != "Admin")
            userMeasuementLibrary.push(measurement)
    })

    // [3] Update Backend with new Library State
    let token = store.getState().user.token
    let libraryId = store.getState().library.libraryId
    let response = await updateUserStudy(
        token,
        { analysis_list: userAnalysisList, measurement_library: userMeasuementLibrary },
        libraryId,
    )
    if(response) {
        loadLibraries(token)
    }
}
export const selectAnalysis = ({ id }) => ({
    type: SELECT_ANALYSIS,
    payload: { id }
})

export const addNewAnalysis = ({ analysisName }) => async dispatch => {
    dispatch({
        type: ADD_NEW_ANALYSIS,
        payload: { analysisName }
    })
    await updateLibraryState()
}

export const deleteAnalysis = ({ id }) => async dispatch => {
    dispatch({
        type: DELETE_ANALYSIS,
        payload: { id }
    })
    await updateLibraryState()
}

export const selectMeasurment = ({ id }) => ({
    type: SELECT_MEASUREMENT,
    payload: { id }
})

export const addNewMeasurement =  () => async dispatch => {
    dispatch({
        type: ADD_NEW_MEASUREMENT,
        payload: {}
    })
    await updateLibraryState()
}

export const deleteMeasurement = ({ id }) => async dispatch => {
    dispatch({
        type: DELETE_MEASUREMENT,
        payload: { id }
    })
    await updateLibraryState()
}

export const updateSelectedMeasurement = (newData) => dispatch => {
    dispatch({
        type: UPDATE_SELECTED_MEASUREMENT,
        payload: newData
    })
}

export const saveSelectedMeasurementUpdate = () => async dispatch => {
    dispatch({
        type: SAVE_SELECTED_MEASUREMENT_UPDATES,
        payload: {}
    })
    await updateLibraryState()
}


export const selectLibraryMeasurements = ({ measurementList }) => ({
    type: SELECT_LIBRARY_MEASUREMENTS,
    payload: { measurementList }
})

export const addSelectedMeasurementToAnalysis = () => async dispatch => {
    dispatch({
        type: ADD_MEASUREMNT_ANALYSIS_LIST,
        payload: {}
    })
    await updateLibraryState()
}

export const removeMeasurementFromSelectedAnalysis = ({ id }) => async dispatch => {
    dispatch({
        type: REMOVE_MEASURMENT_ANALYSIS_LIST,
        payload: { id }
    })
    await updateLibraryState()
}


